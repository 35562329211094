jQuery(document).ready(function () {

    function setEqualHeightForEssentials() {
        jQuery('.essentials-post .isotope-container .t-entry-visual').each(
            function () {
                // Get the height of the current element
                var height = jQuery(this).height();
                //   alert(height);
                // Set the height of the sibling element
                jQuery(this)
                    .siblings('.essentials-post  .isotope-container .t-entry-text')
                    .height(height);
            }
        );
    }

    // function setEqualHeightForCards() {
    //     console.log('aaaaaa');
    //     const rows = document.querySelectorAll('.more-stories .isotope-container'); 
    //     const rows2 = document.querySelectorAll('.essentials-row .isotope-container'); 
    //     const rows3 = document.querySelectorAll('.deepdive-row .isotope-container'); 

    //     rows.forEach(row => {
    //         // if (row.closest('#index-189927')) {
    //         //     return; // Skip this row if it's inside the specified div
    //         // }

    //         let maxHeight = 0;
    //         const titles = row.querySelectorAll('.t-entry-title');

    //         // Find the tallest title in the row
    //         titles.forEach(title => {
    //             title.style.height = 'auto'; // Reset height to get the actual height
    //             const height = title.offsetHeight;
    //             if (height > maxHeight) {
    //                 maxHeight = height;
    //             }
    //         });

    //         // Apply the maximum height to all titles in the row
    //         titles.forEach(title => {
    //             title.style.height = `${maxHeight}px`;
    //         });
    //     });

    //     rows2.forEach(row => {
    //         // if (row.closest('#index-189927')) {
    //         //     return; // Skip this row if it's inside the specified div
    //         // }

    //         let maxHeight = 0;
    //         const titles = row.querySelectorAll('.t-entry-title');

    //         // Find the tallest title in the row
    //         titles.forEach(title => {
    //             title.style.height = 'auto'; // Reset height to get the actual height
    //             const height = title.offsetHeight;
    //             if (height > maxHeight) {
    //                 maxHeight = height;
    //             }
    //         });

    //         // Apply the maximum height to all titles in the row
    //         titles.forEach(title => {
    //             title.style.height = `${maxHeight}px`;
    //         });
    //     });

    //     rows3.forEach(row => {
    //         // if (row.closest('#index-189927')) {
    //         //     return; // Skip this row if it's inside the specified div
    //         // }

    //         let maxHeight = 0;
    //         const titles = row.querySelectorAll('.t-entry-title');

    //         // Find the tallest title in the row
    //         titles.forEach(title => {
    //             title.style.height = 'auto'; // Reset height to get the actual height
    //             const height = title.offsetHeight;
    //             if (height > maxHeight) {
    //                 maxHeight = height;
    //             }
    //         });

    //         // Apply the maximum height to all titles in the row
    //         titles.forEach(title => {
    //             title.style.height = `${maxHeight}px`;
    //         });
    //     });
    // }



    // function setEqualHeightForCards() {
    //     const rows = document.querySelectorAll('.more-stories .isotope-container');
    //     const rows2 = document.querySelectorAll('.essentials-row .isotope-container');
    //     const rows3 = document.querySelectorAll('.deepdive-row .isotope-container');
    //     const rows4 = document.querySelectorAll('.category-page-wrapper .isotope-container');


    //     const setMaxHeightForRow = (row) => {
    //         let maxHeight = 0;
    //         const titles = row.querySelectorAll('.t-entry-title');

    //         titles.forEach(title => {
    //             title.style.height = 'auto';
    //             const height = title.offsetHeight;
    //             if (height > maxHeight) {
    //                 maxHeight = height;
    //             }
    //         });

    //         titles.forEach(title => {
    //             title.style.height = `${maxHeight}px`;
    //         });
    //     };

    //     rows.forEach(row => setMaxHeightForRow(row));
    //     rows2.forEach(row => setMaxHeightForRow(row));
    //     rows3.forEach(row => setMaxHeightForRow(row));
    //     rows4.forEach(row => setMaxHeightForRow(row));
    // }

    function setEqualHeightForCards() {
        const selectors = [
            '.more-stories .isotope-container',
            '.essentials-row .isotope-container',
            '.deepdive-row .isotope-container',
            '.category-page-wrapper .isotope-container'
        ];

        const setMaxHeightForRow = (row) => {
            let maxHeight = 0;
            // Only select titles that haven't been processed
            const titles = row.querySelectorAll('.t-entry-title:not([data-height-processed])');

            // If no unprocessed titles, skip this row
            if (titles.length === 0) {
                return;
            }

            // Get all titles in the row (including processed ones) to ensure consistent height
            const allTitlesInRow = row.querySelectorAll('.t-entry-title');

            // Reset heights for new titles
            titles.forEach(title => {
                title.style.height = 'auto';
            });

            // Calculate max height considering all titles in the row
            allTitlesInRow.forEach(title => {
                const height = title.offsetHeight;
                if (height > maxHeight) {
                    maxHeight = height;
                }
            });

            // Apply max height only to new titles
            titles.forEach(title => {
                title.style.height = `${maxHeight}px`;
                title.setAttribute('data-height-processed', 'true');
            });
        };

        // Process each selector
        selectors.forEach(selector => {
            const rows = document.querySelectorAll(selector);
            rows.forEach(setMaxHeightForRow);
        });
    }






    // function setEqualHeightForStories() {
    //     const rows = document.querySelectorAll('.isotope-container'); // Adjust selector based on your structure

    //     rows.forEach(row => {
    //         if (row.closest('#index-189927')) {
    //             return; // Skip this row if it's inside the specified div
    //         }

    //         let maxHeight = 0;
    //         const titles = row.querySelectorAll('.t-entry-title');

    //         // Find the tallest title in the row
    //         titles.forEach(title => {
    //             title.style.height = 'auto'; // Reset height to get the actual height
    //             const height = title.offsetHeight;
    //             if (height > maxHeight) {
    //                 maxHeight = height;
    //             }
    //         });

    //         // Apply the maximum height to all titles in the row
    //         titles.forEach(title => {
    //             title.style.height = `${maxHeight}px`;
    //         });
    //     });
    // }

    // function setEqualHeightForSimilarReads() {
    //     const rows = document.querySelectorAll('.similar-reads-wrapper .row .vc_row .row .wpb_row .wpb_column .uncol .uncoltable .uncell .uncont .owl-carousel-wrapper .owl-carousel-container #index-650167 .owl-stage-outer .owl-stage '); // Adjust selector based on your structure

    //     rows.forEach(row => {

    //         let maxHeight = 0;
    //         const titles = row.querySelectorAll('.t-entry-title');

    //         // Find the tallest title in the row
    //         titles.forEach(title => {
    //             title.style.height = 'auto'; // Reset height to get the actual height
    //             const height = title.offsetHeight;
    //             if (height > maxHeight) {
    //                 maxHeight = height;
    //             }
    //         });

    //         // Apply the maximum height to all titles in the row
    //         titles.forEach(title => {
    //             title.style.height = `${maxHeight}px`;
    //         });
    //     });


    //     jQuery('#index-650167 .owl-stage-outer .owl-stage .tmb .t-inside .t-entry-text')
    //         .css({
    //             "padding-bottom": "15px",
    //         });


    // }

    // function setEqualHeightForSimilarReadsMobile() {
    //     const rows = document.querySelectorAll('.similar-reads-wrapper-mobile .row .vc_row .row .wpb_row .wpb_column .uncol .uncoltable .uncell .uncont .owl-carousel-wrapper .owl-carousel-container #index-6501679 .owl-stage-outer .owl-stage '); // Adjust selector based on your structure

    //     rows.forEach(row => {

    //         let maxHeight = 0;
    //         const titles = row.querySelectorAll('.t-entry-title');

    //         // Find the tallest title in the row
    //         titles.forEach(title => {
    //             title.style.height = 'auto'; // Reset height to get the actual height
    //             const height = title.offsetHeight;
    //             if (height > maxHeight) {
    //                 maxHeight = height;
    //             }
    //         });

    //         // Apply the maximum height to all titles in the row
    //         titles.forEach(title => {
    //             title.style.height = `${maxHeight}px`;
    //         });
    //     });


    //     jQuery('#index-6501679 .owl-stage-outer .owl-stage .tmb .t-inside')
    //         .css({
    //             "margin-bottom": "50px",
    //         });
    // }

    if (jQuery(window).width() > 766) {
        setEqualHeightForEssentials();
        setEqualHeightForCards();

        jQuery(".loadmore-button").click(function () {
            console.log('Load more clicked');

            setTimeout(() => {
                setEqualHeightForCards();

                const newCategoryLinks = document.querySelectorAll('.t-entry-category:not([data-processed])');
                const newFilterCatLinks = document.querySelectorAll('.filter-cat a:not([data-processed])');

                addIcons([...newCategoryLinks, ...newFilterCatLinks]);
            }, 800);
        });

        let resizeTimer;
        window.addEventListener('resize', () => {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(() => {
                if (jQuery(window).width() > 766) {
                    document.querySelectorAll('.t-entry-title[data-height-processed]')
                        .forEach(title => title.removeAttribute('data-height-processed'));
                    setEqualHeightForCards();
                }
            }, 250);
        });
    }



    // setEqualHeightForSimilarReadsMobile();



    // disable link
    document.querySelectorAll('.tmb-avatar-size-sm').forEach(link => {
        link.addEventListener('click', event => {
            event.preventDefault();
        });

    });

    // remove by from author name
    document.querySelectorAll('.tmb-username-text').forEach(authorTextSpan => {
        authorTextSpan.textContent = authorTextSpan.textContent.replace(/^by\s*/, '');
    });

    //adding icons
    function addIcons(links) {
        const categoryStyles = {
            'Policy': { icon: 'fa-file-text', bgColor: '#d0c4f6' },
            'Leadership': { icon: 'fa-flag', bgColor: '#d9f6ef' },
            'Manufacturing': { icon: 'fa-box', bgColor: '#e2d3ec' },
            'Technology': { icon: 'fa-cog', bgColor: '#c9f2ff' },
            'Supply': { icon: 'fa-truck', bgColor: '#a0d3de' },
        };

        const addCategoryIconAndColor = (link) => {
            const categoryText = link.textContent.trim();
            const categoryStyle = categoryStyles[categoryText];

            if (categoryStyle) {
                if (!link.hasAttribute('data-processed')) {
                    const icon = document.createElement('i');
                    icon.className = `fa ${categoryStyle.icon} fa-push-right`;
                    link.insertAdjacentElement('afterbegin', icon);
                    link.style.backgroundColor = categoryStyle.bgColor;

                    link.setAttribute('data-processed', 'true');  // Mark this link as processed
                }
            }
        };

        links.forEach(addCategoryIconAndColor);
    }


    const initialCategoryLinks = document.querySelectorAll('.t-entry-category');
    const initialFilterCatLinks = document.querySelectorAll('.filter-cat a');
    const initialCategoryLinks2 = document.querySelectorAll('.post-categories li a');

    addIcons([...initialCategoryLinks, ...initialFilterCatLinks, ...initialCategoryLinks2]);

    function setEqualHeightForEqualHeightCard() {

        const rows = document.querySelectorAll('.bg-card-container '); // Adjust selector based on your structure
        //  alert(rows);
        rows.forEach(row => {

            let maxHeight = 0;
            const titles = row.querySelectorAll('.custom-card-equal-height');

            // Find the tallest title in the row
            titles.forEach(title => {
                title.style.height = 'auto'; // Reset height to get the actual height
                const height = title.offsetHeight;
                if (height > maxHeight) {
                    maxHeight = height;
                }
            });

            // Apply the maximum height to all titles in the row
            titles.forEach(title => {
                title.style.height = `${maxHeight}px`;
            });
        });
    }

});

function wiggle() {
    document.addEventListener('DOMContentLoaded', (event) => {
        let el = document.querySelector('.deepdive-post .isotope-wrapper .isotope-container .tmb .t-inside .t-entry-visual ');

        if (el) {
            const height = el.clientHeight;
            const width = el.clientWidth;

            el.addEventListener('mousemove', handleMove);

            function handleMove(e) {
                const xVal = e.layerX;
                const yVal = e.layerY;

                const yRotation = 20 * ((xVal - width / 2) / width);
                const xRotation = -20 * ((yVal - height / 2) / height);

                const string = 'perspective(1000px) scale(1) rotateX(' + xRotation + 'deg) rotateY(' + yRotation + 'deg)';

                el.style.transform = string;
            }

            el.addEventListener('mouseout', function () {
                el.style.transform = 'perspective(1000px) scale(1) rotateX(0) rotateY(0)';
                el.style.transition = 'transform 0.5s ease-out';
            });

            // el.addEventListener('mouseleft', function (){
            //     el.style.transform = 'scale(1.5) rotateX(1) rotateY(2)';
            // })

            el.addEventListener('mousedown', function () {
                el.style.transform = 'perspective(1000px) scale(0.95) rotateX(0) rotateY(0)';
            });

            el.addEventListener('mouseup', function () {
                el.style.transform = 'perspective(1000px) scale(1) rotateX(0) rotateY(0)';
                el.style.transition = 'transform 0.2s ease-out';
            });
        }
    });



}

// wiggle();

function stickysidebar() {
    // Check if the #primary element exists
    if (!document.getElementById("primary")) {
        return; // Exit the function if #primary is not present
    }

    // let header = document.querySelector(".menu-wrapper #masthead .menu-container");

    let sidebar = document.getElementsByClassName("info")[0];
    let sidebar_content = document.getElementsByClassName("info-wrapper")[0];
    let footer = document.querySelector("footer");

    window.addEventListener('scroll', function () {
        let scrollTop = window.scrollY;
        let viewportHeight = window.innerHeight;
        let contentHeight = sidebar_content.getBoundingClientRect().height;
        let sidebarTop = sidebar.getBoundingClientRect().top + window.pageYOffset;
        let footerTop = footer.getBoundingClientRect().top + window.pageYOffset;

        if (scrollTop + viewportHeight >= footerTop) {
            sidebar_content.style.transform = "";
            sidebar_content.style.position = "";
            // header.style.position = "absolute";
        } else if (scrollTop >= contentHeight - viewportHeight + sidebarTop) {
            sidebar_content.style.position = "fixed";
            sidebar_content.style.width = "25%";
            // header.style.position = "fixed";
            // sidebar_content.style.marginTop = "0rem";
        } else {
            sidebar_content.style.position = "fixed";
            sidebar_content.style.width = "25%";
            // header.style.position = "fixed";
        }
    });
}


stickysidebar();


let reveal_text = document.getElementById('reveal-btn');

if (reveal_text) {
    reveal_text.addEventListener('click', function (event) {
        event.preventDefault();
        const revealText = document.querySelector('.reveal-text');
        revealText.classList.remove('hidden');
        revealText.classList.add('visible');
        this.classList.add('hidden');
    });
}


//header menu overlay to hide
document.addEventListener('DOMContentLoaded', () => {

    const menuItems = document.querySelectorAll('.menu-item-link a');
    const overlay = document.querySelector('.overlay');
    const closeButton = document.querySelector('.mobile-menu-button');
    const htmlElement = document.documentElement;

    menuItems.forEach(item => {
        item.addEventListener('click', (e) => {
            if (item.getAttribute('href') && item.getAttribute('href') !== '#') {
                if (overlay) {
                    overlay.classList.remove('open');
                    closeButton.classList.remove('close');
                    htmlElement.classList.remove('overlay-open');
                }
            }
        });
    });
});


